import { Contracts } from '../types';
import * as arbitrum from '@/assets/data/contracts/arbitrum.json';

const contracts: Contracts = {
  merkleRedeem: '0x6bd0B17713aaa29A2d7c9A39dDc120114f9fD809',
  merkleOrchard: '0x751A0bC0e3f75b38e01Cf25bFCE7fF36DE1C87DE',
  merkleOrchardV2: arbitrum.MerkleOrchard,
  multicall: '0xcA11bde05977b3631167028862bE2a173976CA11',
  authorizer: '0x6207ed574152496c9B072C24FD87cE9cd9E17320',
  vault: arbitrum.Vault,
  weightedPoolFactory: arbitrum.WeightedPoolFactory,
  stablePoolFactory: '0x2433477A10FC5d31B9513C638F19eE85CaED53Fd',
  lidoRelayer: '',
  balancerHelpers: arbitrum.BalancerHelpers,
  batchRelayer: arbitrum.BalancerRelayer,
  gaugeFactory: arbitrum.ChildChainLiquidityGaugeFactory,
  balancerMinter: arbitrum.L2BalancerPseudoMinter,
  gaugeController: '',
  tokenAdmin: '',
  veBAL: '',
  veDelegationProxy: '0x81cFAE226343B24BA12EC6521Db2C79E7aeeb310',
  veBALHelpers: '',
  feeDistributor: '',
  feeDistributorDeprecated: '',
  gaugeRewardsHelper: arbitrum.ChildChainGaugeRewardHelper,
  gaugeWorkingBalanceHelper: arbitrum.ChildChainGaugeWorkingBalanceHelper,
  faucet: '',

  distributionAssessor: '0xE77E059b002AACb638EBbF117330e96B7Cbdf920',
  securitizationPoolValueService: '0xDA3b124e81Cb0a1705d4D9A98250472DC6E4bA7B',
  acceptedInvoiceToken: '0x8fbbE267977850e2b5D2BB9528C5D53241b61Bb8',
  distributionOperator: '0x86307f39FB0c376398b2DCb143f69A52a3E7f08d',

  untangled: {
    securitizationManagementProject:
      '0xB55f7A291Dd94Af78Dc3A3FfdBe6f2353c138E51',
    securitizationPoolValueService:
      '0xDA3b124e81Cb0a1705d4D9A98250472DC6E4bA7B',
    distributionAssessor: '0xE77E059b002AACb638EBbF117330e96B7Cbdf920',
    distributionTranche: '0xeCB661b470337a79103d922546Fe105fE46B2850',
    distributionOperator: '0x86307f39FB0c376398b2DCb143f69A52a3E7f08d',
    uniqueIdentity: '0x076E55665d6997343b1e45f6609258273231c6c8',
    loanAssetToken: '0xa052454d5C2428234825c0c41999cf37001D5eDd',
  },
};

export default contracts;
