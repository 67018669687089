import { lsGet, lsRemove, lsSet } from '@/lib/utils';
import moment from 'moment';

import LS_KEYS from '@/constants/local-storage.keys';

export interface AuthTokenPayload {
  token: string;
  exp: string;
  address: string;
}
export const setAuthToken = (payload: AuthTokenPayload) => {
  const tokens = getAuthTokens();
  const newTokens = [...tokens];

  const tokenIdx = newTokens.findIndex(x => x.address === payload.address);

  if (tokenIdx === -1) {
    newTokens.push(payload);
  } else {
    newTokens[tokenIdx] = payload;
  }

  lsSet(LS_KEYS.Token.Kyc, newTokens, undefined);
};

export const getAuthTokens = () => {
  const tokens = lsGet<AuthTokenPayload[] | null>(
    LS_KEYS.Token.Kyc,
    undefined,
    undefined
  );

  return tokens ?? [];
};

export const getAuthTokenByAddress = (address: string) => {
  const tokens = getAuthTokens();
  return tokens.find(x => x.address === address);
};

export const checkAuthTokenValid = (address: string) => {
  const authToken = getAuthTokenByAddress(address);

  if (
    authToken &&
    authToken.address === address &&
    moment(authToken.exp).diff(moment(), 'hour') > 0
  ) {
    return true;
  }

  return false;
};

export const getCurrentAuthToken = () => {
  const currentAccount = lsGet('connectedWallet');
  if (!currentAccount) return undefined;

  return getAuthTokenByAddress(currentAccount);
};

export const cleanupTokens = () => {
  lsRemove(LS_KEYS.Token.Kyc);
  lsRemove('connectedWallet');
};
