import axios from 'axios';
import { configService } from '../config/config.service';
import { networkId } from '@/composables/useNetwork';
import { getCurrentAuthToken } from '../auth';

const BASE_URL: string = configService.env.API_BACKEND_V2;

const instance = axios.create({
  baseURL: BASE_URL + `/${networkId.value}`,
});

instance.interceptors.request.use(cfg => {
  const kycToken = getCurrentAuthToken();

  if (kycToken?.token) {
    cfg.headers.Authorization = `Bearer ${kycToken?.token}`;
  }

  return cfg;
});

export default instance;
