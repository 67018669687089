export enum VerificationStatus {
  UNINITIATED = -1,
  PENDING = 0,
  ACCEPTED = 1,
  REJECTED = 2,
  STATUS_CHANGED = 3,
  DELETED = 4,
  RECEIVED = 5,
  REVIEWING = 6,
}

export enum UserType {
  US_INDIVIDUAL = 'US_INDIVIDUAL',
  NON_US_INDIVIDUAL = 'NON_US_INDIVIDUAL',
  US_BUSINESS = 'US_BUSINESS',
  NON_US_BUSINESS = 'NON_US_BUSINESS',
}

export const LabelByUserType = {
  [UserType.US_INDIVIDUAL]: 'US Individual',
  [UserType.NON_US_INDIVIDUAL]: 'Non-US Individual',
  [UserType.US_BUSINESS]: 'US Business',
  [UserType.NON_US_BUSINESS]: 'Non-US Business',
};
