import { Contracts } from '../types';
import * as arbitrum from '@/assets/data/contracts/arbitrum.json';

const contracts: Contracts = {
  merkleRedeem: '0x6bd0B17713aaa29A2d7c9A39dDc120114f9fD809',
  merkleOrchard: '0x751A0bC0e3f75b38e01Cf25bFCE7fF36DE1C87DE',
  merkleOrchardV2: arbitrum.MerkleOrchard,
  multicall: '0xcA11bde05977b3631167028862bE2a173976CA11',
  authorizer: '0x6207ed574152496c9B072C24FD87cE9cd9E17320',
  vault: '',
  weightedPoolFactory: arbitrum.WeightedPoolFactory,
  stablePoolFactory: '0x2433477A10FC5d31B9513C638F19eE85CaED53Fd',
  lidoRelayer: '',
  balancerHelpers: arbitrum.BalancerHelpers,
  batchRelayer: arbitrum.BalancerRelayer,
  gaugeFactory: arbitrum.ChildChainLiquidityGaugeFactory,
  balancerMinter: arbitrum.L2BalancerPseudoMinter,
  gaugeController: '',
  tokenAdmin: '',
  veBAL: '',
  veDelegationProxy: '0x81cFAE226343B24BA12EC6521Db2C79E7aeeb310',
  veBALHelpers: '',
  feeDistributor: '',
  feeDistributorDeprecated: '',
  faucet: '',
  distributionAssessor: '0xd807BDDaA46930c9E1f4bE1a95AF2851Ce0d1405',
  securitizationPoolValueService: '0x26Fa77B7c576713903CFCa3223E63ee3185A41b3',
  acceptedInvoiceToken: '0xe3f840Fa081f7b1c81a5F5958bab1786FE6d7c36',
  gaugeRewardsHelper: arbitrum.ChildChainGaugeRewardHelper,
  gaugeWorkingBalanceHelper: arbitrum.ChildChainGaugeWorkingBalanceHelper,

  distributionOperator: '0x309296EC399135CE25BFf36c21B2FbD778fF9e0D',

  untangled: {
    securitizationManagementProject:
      '0x4DCC7a839CE7e952Cd90d03d65C70B9CCD6BA4C2',
    securitizationPoolValueService:
      '0x8dA445046281e6FBf2aBAd56952331B9D5Ea8d45',
    noteTokenVault: '0x8D933c50852f0739199dcc193Fc5Ad8aC54C3296',
    distributionAssessor: '',
    distributionTranche: '',
    distributionOperator: '',
    uniqueIdentity: '0x23810D0e7022D785bA679057E4FbeBFeD99617C7',
    loanAssetToken: '0x5147C3476E675FBDbFeF8E2576A5bDe20b438B0B',
  },
};

export default contracts;
