import { Contracts } from '../types';
import * as arbitrum from '@/assets/data/contracts/arbitrum.json';

const contracts: Contracts = {
  merkleRedeem: '0x6bd0B17713aaa29A2d7c9A39dDc120114f9fD809',
  merkleOrchard: '0x751A0bC0e3f75b38e01Cf25bFCE7fF36DE1C87DE',
  merkleOrchardV2: arbitrum.MerkleOrchard,
  multicall: '0xcA11bde05977b3631167028862bE2a173976CA11',
  authorizer: '0x6207ed574152496c9B072C24FD87cE9cd9E17320',
  vault: arbitrum.Vault,
  weightedPoolFactory: arbitrum.WeightedPoolFactory,
  stablePoolFactory: '0x2433477A10FC5d31B9513C638F19eE85CaED53Fd',
  lidoRelayer: '',
  balancerHelpers: arbitrum.BalancerHelpers,
  batchRelayer: arbitrum.BalancerRelayer,
  gaugeFactory: arbitrum.ChildChainLiquidityGaugeFactory,
  balancerMinter: arbitrum.L2BalancerPseudoMinter,
  gaugeController: '',
  tokenAdmin: '',
  veBAL: '',
  veDelegationProxy: '0x81cFAE226343B24BA12EC6521Db2C79E7aeeb310',
  veBALHelpers: '',
  feeDistributor: '',
  feeDistributorDeprecated: '',
  faucet: '',
  gaugeRewardsHelper: arbitrum.ChildChainGaugeRewardHelper,
  gaugeWorkingBalanceHelper: arbitrum.ChildChainGaugeWorkingBalanceHelper,

  distributionAssessor: '',
  securitizationPoolValueService: '0x740EB077B9D14B68eB37FbB1405F5bC9a131ba44',
  acceptedInvoiceToken: '',
  distributionOperator: '',

  untangled: {
    securitizationManagementProject:
      '0xD66f725910F04D219e68CcD78f0294703c046e69',
    securitizationPoolValueService:
      '0x740EB077B9D14B68eB37FbB1405F5bC9a131ba44',
    noteTokenVault: '0xCaFcb121A7f9Ea2974F25967Afb3Bb7F55C8bDf8',
    distributionAssessor: '', // TODO Should be removed
    distributionTranche: '', // TODO should be removed
    distributionOperator: '', // TODO safe to be removed
    uniqueIdentity: '0xaB1B5f0a792132B979121EA241664295Dc267267',
    loanAssetToken: '0x2E0ee3885023a36383D88E93d3f74121863e06AA',
  },
};

export default contracts;
