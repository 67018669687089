import { Config } from '../types';
import keys from './keys';
import contracts from './contracts';
import pools from './pools';
import tokenlists from './tokenlists';
import tokens from './tokens';

const config: Config = {
  key: '80001',
  chainId: 80001,
  layerZeroChainId: 137,
  supportsVeBalSync: false,
  chainName: 'Mumbai',
  name: 'Mumbai',
  shortName: 'Mumbai',
  monorepoName: 'mumbai',
  slug: 'mumbai',
  network: 'mumbai',
  trustWalletNetwork: 'mumbai',
  unknown: false,
  visibleInUI: true,
  testNetwork: true,
  rpc: `https://polygon-mumbai.blockpi.network/v1/rpc/public`,
  ws: `wss://polygon-mumbai-bor.publicnode.com`,
  publicRpc: 'https://polygon-mumbai.blockpi.network/v1/rpc/public',
  explorer: 'https://mumbai.polygonscan.com',
  explorerName: 'Mumbai',
  subgraph:
    'https://api.thegraph.com/subgraphs/name/balancer-labs/balancer-arbitrum-v2',
  balancerApi: 'https://api.balancer.fi',
  poolsUrlV2: '',
  subgraphs: {
    main: [
      'https://api.thegraph.com/subgraphs/name/balancer-labs/balancer-arbitrum-v2',
    ],
    aave: '',
    gauge:
      'https://api.thegraph.com/subgraphs/name/balancer-labs/balancer-gauges-arbitrum',
    blocks:
      'https://api.thegraph.com/subgraphs/name/ianlapham/arbitrum-one-blocks',
  },
  bridgeUrl: 'https://bridge.arbitrum.io/',
  supportsEIP1559: false,
  supportsElementPools: false,
  blockTime: 2,
  nativeAsset: {
    name: 'Matic',
    address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
    symbol: 'MATIC',
    decimals: 18,
    deeplinkId: 'matic',
    logoURI: 'tokens/matic.svg',
    minTransactionBuffer: '0.1',
  },
  thirdParty: {
    coingecko: {
      nativeAssetId: 'ethereum',
      platformId: 'mumbai-one',
    },
    apyVision: {
      networkName: 'mumbai',
    },
  },
  addresses: {
    ...contracts,
  },
  pools,
  tokens,
  keys,
  gauges: {
    type: 3,
    weight: 0,
  },
  tokenlists,
  rateProviders: {},
};

export default config;
