import { Config } from '../types';
import keys from './keys';
import contracts from './contracts';
import pools from './pools';
import tokenlists from './tokenlists';
import tokens from './tokens';
import rateProviders from './rateProviders';

const config: Config = {
  key: '42220',
  chainId: 42220,
  //   layerZeroChainId: 110,
  supportsVeBalSync: true,
  chainName: 'Celo',
  name: 'Celo',
  shortName: 'Celo',
  monorepoName: 'celo',
  slug: 'celo',
  network: 'celo',
  trustWalletNetwork: 'celo',
  unknown: false,
  visibleInUI: true,
  testNetwork: false,
  rpc: `https://forno.celo.org`,
  ws: `wss://forno.celo.org/ws`,
  publicRpc: 'https://forno.celo.org',
  explorer: 'https://celoscan.io/',
  explorerName: 'CeloScan',
  subgraph:
    'https://api.thegraph.com/subgraphs/name/balancer-labs/balancer-arbitrum-v2',
  balancerApi: 'https://api.balancer.fi',
  poolsUrlV2: '',
  subgraphs: {
    main: [
      'https://api.thegraph.com/subgraphs/name/balancer-labs/balancer-arbitrum-v2',
    ],
    aave: '',
    gauge:
      'https://api.thegraph.com/subgraphs/name/balancer-labs/balancer-gauges-arbitrum',
    blocks:
      'https://api.thegraph.com/subgraphs/name/ianlapham/arbitrum-one-blocks',
  },
  bridgeUrl: 'https://bridge.orbitchain.io/celo/celo',
  supportsEIP1559: true,
  supportsElementPools: false,
  blockTime: 5,
  nativeAsset: {
    name: 'CELO',
    address: '0x0000000000000000000000000000000000000000',
    symbol: 'CELO',
    decimals: 18,
    deeplinkId: 'celo',
    logoURI: 'tokens/celo.png',
    minTransactionBuffer: '0.1',
  },
  thirdParty: {
    coingecko: {
      nativeAssetId: 'ethereum',
      platformId: 'alfajores-one',
    },
    apyVision: {
      networkName: 'alfajores',
    },
  },
  addresses: {
    ...contracts,
  },
  pools,
  tokens,
  keys,
  gauges: {
    type: 3,
    weight: 0,
  },
  tokenlists,
  rateProviders,
};

export default config;
