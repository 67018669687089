import '@/assets/css/tailwind.css';
import '@/assets/css/index.css';
import 'vue3-virtual-scroller/dist/vue3-virtual-scroller.css';

import { createApp } from 'vue';
import registerDirectives from '@/plugins/directives';
import { registerPlugins } from '@/plugins';
import initSentry from '@/plugins/sentry';
import Jazzicon from 'vue3-jazzicon/src/components';
import { VueSignaturePad } from 'vue-signature-pad';
import prismic from './prismic';

import Root from './Root.vue';
import { initDependencies } from './dependencies';

(() => {
  const buildTime = process.env.VITE_BUILD_TIME;
  if (buildTime) {
    console.log(
      `Build time: %c${new Date(Number(buildTime))}`,
      'color: #bada55'
    );
  }
})();

initDependencies();

const app = createApp(Root);
app.use(prismic);
app.component('Jazzicon', Jazzicon);
app.component('VueSignaturePad', VueSignaturePad);

registerPlugins(app);
registerDirectives(app);
initSentry(app);

app.mount('#app');

export default app;
