<template>
  <BalModal :show="isVisible" class="third-parties" @close="emit('close')">
    <div v-html="dataRef"></div>
  </BalModal>
</template>

<script setup lang="ts">
import request from '../../services/api/untangled-axios';

const dataRef = ref('');

watchEffect(async () => {
  const { data } = await request.get(`/api/v1/docs/Company_Cookies`);
  const { data: docs } = await request.get(data.sasUrl);

  dataRef.value = docs;
});

type Props = {
  isVisible: boolean;
};

defineProps<Props>();

const emit = defineEmits<{
  (e: 'close'): void;
}>();
</script>

<style scoped>
/* If this modal is placed on top of the Connect Wallet modal */
.bal-modal + .bal-modal.third-parties :deep(.modal-bg) {
  @apply bg-opacity-0;
}
</style>
