import { computed, reactive, Ref, ref } from 'vue';
import { useQuery, UseQueryOptions } from '@tanstack/vue-query';

import QUERY_KEYS from '@/constants/queryKeys';
import { BalanceMap } from '@/services/token/concerns/balances.concern';

import useWeb3 from '@/services/web3/useWeb3';
import { TokenInfoMap } from '@/types/TokenList';
import { default as ERC20_ABI } from '@/lib/abi/ERC20.json';

import useNetwork from '../useNetwork';
import { getMulticaller } from '@/dependencies/Multicaller';
import { BigNumber } from '@ethersproject/bignumber';
import { formatUnits } from '@ethersproject/units';

/**
 * TYPES
 */
type QueryResponse = BalanceMap;
type QueryOptions = UseQueryOptions<QueryResponse>;
interface QueryInputs {
  tokenAddresses: Ref<string[]>;
  isEnabled?: Ref<boolean>;
}

/**
 * Fetches all balances for provided tokens.
 */
export default function useBalancesQuery({
  tokenAddresses,
  isEnabled = ref(true),
}: QueryInputs) {
  /**
   * COMPOSABLES
   */
  const { account, isWalletReady, getProvider: getWeb3Provider } = useWeb3();
  const { networkId, networkConfig } = useNetwork();

  /**
   * COMPUTED
   */
  const enabled = computed(
    () => isWalletReady.value && !!networkConfig && isEnabled.value
  );
  const web3Provider = computed(() => getWeb3Provider());

  /**
   * QUERY INPUTS
   */
  const queryKey = reactive(
    QUERY_KEYS.Account.Balances(networkId, account, tokenAddresses)
  );

  const queryFn = async () => {
    // const tokenAddresses = ref(['0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE']);
    if (!networkConfig) {
      throw new Error('networkConfig is undefined');
    }

    const hasNativeToken = tokenAddresses.value.some(
      token => token === networkConfig.nativeAsset.address
    );
    const erc20TokenAddresses = tokenAddresses.value.filter(
      addr => addr !== networkConfig.nativeAsset.address
    );

    let balanceResult = {} as Record<string, { balanceOf: BigNumber }>;
    let decimalsResult = {} as Record<string, { decimals: number }>;

    if (erc20TokenAddresses.length) {
      const Multicaller = getMulticaller();
      const multicaller = new Multicaller();

      erc20TokenAddresses.forEach(tokenAddress => {
        multicaller.call({
          key: `${account.value}.${tokenAddress}.balanceOf`,
          address: tokenAddress,
          function: 'balanceOf',
          abi: ERC20_ABI,
          params: [account.value],
        });
      });

      balanceResult = (await multicaller.execute())[account.value];
    }

    if (erc20TokenAddresses.length) {
      const Multicaller = getMulticaller();
      const multicaller = new Multicaller();

      erc20TokenAddresses.forEach(tokenAddress => {
        multicaller.call({
          key: `${account.value}.${tokenAddress}.decimals`,
          address: tokenAddress,
          function: 'decimals',
          abi: ERC20_ABI,
        });
      });

      decimalsResult = (await multicaller.execute())[account.value];
    }

    const tokenBalanceByAddress = {} as Record<string, string>;

    Object.entries(balanceResult).forEach(([tokenAddress, tokenBalance]) => {
      const decimals = decimalsResult[tokenAddress]?.decimals;
      if (!decimals) {
        return;
      }

      const floatBalance = formatUnits(tokenBalance.balanceOf, decimals);
      tokenBalanceByAddress[tokenAddress] = floatBalance;
    });

    if (hasNativeToken) {
      const result = await web3Provider.value.getBalance(account.value);
      const floatBalance = formatUnits(
        result,
        networkConfig.nativeAsset.decimals
      );
      tokenBalanceByAddress[networkConfig.nativeAsset.address] = floatBalance;
    }

    return tokenBalanceByAddress;
  };

  const queryOptions = reactive({
    enabled,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  return useQuery<QueryResponse>(
    queryKey,
    queryFn,
    queryOptions as QueryOptions
  );
}
