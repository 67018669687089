<script lang="ts" setup>
// import useDarkMode from '@/composables/useDarkMode';
// import useTailwind from '@/composables/useTailwind';
import { computed } from 'vue';

import useDarkMode from '@/composables/useDarkMode';
/**
 * TYPES
 */
type Props = {
  forceDark?: boolean;
  imageClass?: string;
  size?: number;
};

/**
 * PROPS & EMITS
 */
const props = withDefaults(defineProps<Props>(), {
  forceDark: false,
});

/**
 * COMPOSABLES
 */
/*
const { darkMode } = useDarkMode();
const tailwind = useTailwind();
*/
const { darkMode } = useDarkMode();
/**
 * COMPUTED
 */
/*
const fillColor = computed(() => {
  if (props.forceDark) return tailwind.theme.colors.white;

  return darkMode.value
    ? tailwind.theme.colors.white
    : tailwind.theme.colors.gray['900'];
});
*/
const useDarkLogo = computed(() => (props.forceDark ? true : darkMode.value));
const logoSrc = computed(() =>
  useDarkLogo.value
    ? '/images/logos/light-logo.png'
    : '/images/logos/dark-logo.png'
);
</script>

<template>
  <div class="flex items-center w-auto h-10 md:h-8">
    <img
      id="logo"
      :class="['logo-svg h-full w-auto', props.imageClass]"
      aria-labelledby="logoTitle logoDesc"
      :src="logoSrc"
    />
  </div>
</template>

<style scoped>
.app-logo :deep(.logotype) {
  @apply origin-top-left relative;

  transform: scale(1.1);
}

.svg-path {
  transition: fill 0.2s ease-out;
}

.logo-svg:hover .svg-path {
  fill: theme('colors.purple.700');
}

.dark .logo-svg:hover .svg-path {
  fill: theme('colors.yellow.500');
}
</style>
